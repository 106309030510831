<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        md="3"
        v-for="account in accounts"
        :key="account.account"
      >
        <v-alert
          outlined
          :color="$store.state.primaryColor"
          @click="openAccount(account.route)"
          style="cursor: pointer"
        >
          <v-layout class="justify-space-between">
            <span class="subtitle-2 light-blue--text text--darken-4">{{
              account.account
            }}</span>
            <v-avatar
              class="caption deep-orange darken-2 white--text"
              size="20"
            >
              {{ account.badge }}
            </v-avatar>
          </v-layout>
        </v-alert>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data: () => ({}),

  computed: {
    accounts() {
      return [
        {
          account: this.$t("chart account"),
          badge: this.$store.state.accounts.length,
          route: "ChartAccount",
        },
        { account: this.$t("sales invoice"), badge: 0, route: "SalesInvoice" },
        { account: this.$t("journal entry"), badge: 0, route: "JournalEntry" },
        {
          account: this.$t("purchase invoice"),
          badge: 0,
          route: "PurchaseInvoice",
        },
        {
          account: this.$t("receivables"),
          badge: 0,
          route: "AccountReceivables",
        },
        { account: this.$t("payables"), badge: 0, route: "Payables" },
        { account: this.$t("payment entry"), badge: 0, route: "PaymentEntry" },
        { account: this.$t("reports"), badge: 0, route: "ReportAccount" },
      ];
    },
  },
  mounted() {
    this.getBadges();
  },

  methods: {
    getBadges() {},

    openAccount(route) {
      this.$router.push({ name: route });
    },
  },
};
</script>
